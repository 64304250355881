import { d as createSubscriptions } from "./reverse.correct.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c("a-modal", {
          staticClass: "reverse-intake",
          attrs: {
            "after-close": _vm.afterModalClose,
            "confirm-loading": _vm.isSubmitting,
            "destroy-on-close": "",
            "visible": _vm.visible,
            "title": "Reverse",
            "width": 832
          },
          on: {
            "cancel": _vm.cancel,
            "ok": function($event) {
              return handleSubmit(_vm.ok);
            }
          }
        }, [_c("a-row", {
          attrs: {
            "gutter": 24
          }
        }, [_c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("select-input", {
          attrs: {
            "form-item": "",
            "label": "Original Intake Code",
            "reference": "grower.common.intakeEdit-intake",
            "source": "intakeId",
            "source-label": "intakeNumber",
            "lazy": "",
            "custom-search": "",
            "rules": "required"
          },
          on: {
            "search": _vm.onIntakeSearch,
            "change": _vm.onIntakeChange
          }
        })], 1)], 1), _c("a-row", {
          attrs: {
            "gutter": 24
          }
        }, [_c("a-col", {
          attrs: {
            "span": 8
          }
        }, [_c("text-input", {
          attrs: {
            "disabled": "",
            "form-item": "",
            "label": "Reverse - New Intake Code",
            "value": _vm.nextIntakeNumber
          }
        })], 1), _c("a-col", {
          attrs: {
            "span": 16
          }
        }, [_c("text-input", {
          attrs: {
            "form-item": "",
            "label": "Reverse Reason",
            "rules": "required"
          },
          on: {
            "change": function($event) {
              return _vm.storeValue("reason", $event);
            }
          }
        })], 1)], 1)], 1)];
      }
    }])
  });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ReverseIntake",
  inject: ["resourceProps"],
  data() {
    const [
      reverseCorrectProps,
      intakeProps,
      intakeEditProps
    ] = this.resourceProps;
    return {
      intakeEditProps,
      intakeProps,
      isSubmitting: false,
      nextIntakeNumber: void 0,
      reverseCorrectProps,
      selectedIntakeId: void 0,
      visible: false
    };
  },
  async mounted() {
    this.reverseCorrectProps.crud.initEntity();
    const { apiUrl } = this.reverseCorrectProps;
    const { data } = await this.axios.get(`${apiUrl}/grower/common/sys-control/next-intake-number`);
    this.nextIntakeNumber = data.nextIntake;
    this.storeValue("nextIntakeNumber", data.nextIntake);
    this.visible = true;
  },
  methods: {
    cancel() {
      this.visible = false;
    },
    async ok() {
      this.isSubmitting = true;
      const { apiUrl } = this.reverseCorrectProps;
      const url = `${apiUrl}/grower/intake-edits/${this.selectedIntakeId}/reverse`;
      try {
        await this.reverseCorrectProps.crud.submitEntity("create", url);
        await this.reverseCorrectProps.crud.fetchList();
        this.visible = false;
      } finally {
        this.isSubmitting = false;
      }
    },
    storeValue(resourceKey, value) {
      this.reverseCorrectProps.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    },
    afterModalClose() {
      this.$router.push(this.reverseCorrectProps.redirectRoute);
    },
    onIntakeChange(intakeId) {
      this.selectedIntakeId = intakeId;
    },
    onIntakeSearch(searchText) {
      if (searchText.length > 2) {
        const { apiUrl } = this.intakeEditProps;
        const query = `intakeNumber=${searchText}`;
        this.intakeEditProps.crud.fetchList(`${apiUrl}/grower/common/intakeEdit-intake?${query}`, void 0, false);
      }
      return;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ReverseIntake = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("resource", {
    attrs: {
      "name": ["grower.intake-edits", "grower.intakes", "grower.common.intakeEdit-intake"],
      "api-url": _vm.apiUrl,
      "redirect-route": ["/watties-grower/reverse-correct-intakes"],
      "resource-id-name": ["id", "id", "intakeId"],
      "subscriptions": [_vm.subscriptions, {}, {}]
    }
  }, [_c("reverse-intake")], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    ReverseIntake
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      subscriptions: createSubscriptions()
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
